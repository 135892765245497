import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  TextField
} from "@mui/material";

import { Form, useActionData } from "react-router-dom";

import { useState } from "react";

import { useData } from "../../hooks/useData";

import Autocomplete from "./components/Autocomplete";

import {
  createAutocompleteOptions,
  getServerOptions,
} from "./utils/autocompleteUtils.js";

const AudienceForm = ({
  action,
  isEditing = false,
  audience = {
    id: null,
    human_readable_name: null,
    name: null,
    segments: [],
  },
}) => {
  const managedplus_audience = audience.managedplus_audience || {};
  const [value, setValue] = useState(
    createAutocompleteOptions(audience?.segments || [])
  );
  const [managedplusAudience, setManagedplusAudience] = useState(
    !!managedplus_audience?.managedplus_category_id
  );
  const { loading, data } = useData(
    "audience",
    "/v1/managedplus_audiences/categories"
  );
  const form = useActionData();
  const categories = data?.result || [];

  return (
    <Form method="post" action={action} role="form" name="audience-form">
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Key"
            name="name"
            error={form?.errors?.hasOwnProperty("/name")}
            helperText={form?.errors?.["/name"]}
            required
            defaultValue={audience.name}
            inputProps={{
              title: "only letters and underscore are allowed",
              pattern: "^[a-zA-Z_]+$",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Audience Name"
            name="human_readable_name"
            defaultValue={audience.human_readable_name}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Autocomplete
            data-testid="my-autocomplete"
            getServerOptions={getServerOptions}
            onValueChange={setValue}
            defaultValue={value}
            renderInput={(params) => (
              <TextField
                error={form?.errors?.hasOwnProperty("/segment_ids")}
                helperText={form?.errors?.["/segment_ids"]}
                {...params}
                label="Search Segments"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setManagedplusAudience((v) => !v)}
                checked={managedplusAudience}
              />
            }
            label="Managed+ Audience"
            slotProps={{ typography: { sx: { fontWeight: 600 } }}}
          />
        </Grid>
        {managedplusAudience && (
          <>
            <Grid item xs={12}>
              <InputLabel required variant="standard" sx={{ fontWeight: 500, fontSize: "0.875rem", color: "text.primary", lineHeight: "1.3125em", mb: 0.25, letterSpacing: "0.006875em" }}>
                Managed+ Audience Category
              </InputLabel>
              {loading ? (
                <TextField fullWidth placeholder="Loading..." disabled />
              ) : (
                <TextField
                  name="managedplus_audience.managedplus_category_id"
                  fullWidth
                  variant="outlined"
                  select
                  error={form?.errors?.hasOwnProperty(
                    "/managedplus_audience/managedplus_category_id"
                  )}
                  helperText={
                    form?.errors?.[
                      "/managedplus_audience/managedplus_category_id"
                    ]
                  }
                  SelectProps={{
                    sx: {
                      "& .MuiSelect-select .notranslate::after": { content: '"Select a Managed+ Category"' },
                      "& .MuiSelect-select .notranslate": { color: "text.disabled" }
                    },
                    defaultValue: managedplus_audience.managedplus_category_id ?? ""
                  }}
                >
                  {categories.map(({ category_id, category_name }) => (
                    <MenuItem key={category_id} value={category_id}>
                      {category_name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel required variant="standard" sx={{ fontWeight: 500, fontSize: "0.875rem", color: "text.primary", lineHeight: "1.3125em", mb: 0.25, letterSpacing: "0.006875em" }}>
                Managed+ Audience Display Name
              </InputLabel>
              <TextField
                name="managedplus_audience.display_name"
                error={form?.errors?.hasOwnProperty(
                  "/managedplus_audience/display_name"
                )}
                helperText={
                  form?.errors?.["/managedplus_audience/display_name"]
                }
                placeholder="Enter the audience name to be displayed on Managed+"
                fullWidth
                required
                variant="outlined"
                defaultValue={managedplus_audience.display_name}
              />
            </Grid>
            <input
              type="hidden"
              name="managedplus_audience.id"
              value={managedplus_audience.id}
            />
          </>
        )}
        {managedplus_audience?.id && (
          <input
            type="hidden"
            name="managedplus_audience.delete"
            value={!managedplusAudience}
          />
        )}
        <input
          type="hidden"
          name="segment_ids"
          value={value.map(({ id }) => id).join(",")}
        />
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Button type="submit" variant="contained">
            {isEditing ? "Save Changes" : "Create Audience"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AudienceForm;
